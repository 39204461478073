import React, { useEffect, useState } from "react";
import { Col, Container, Row, NavLink, Button } from "react-bootstrap";
import { DashboardIcon } from "../Icons/sidebarIcons";
import AppLogo from "./../../../../resource/img/happy-math-logo.svg";
import OffCanvas from "../Sidebar/offCanvas";
import "./style.scss";

function Header() {
  const [open, setOpen] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated")
  );

  const [user, setUser] = React.useState({});

  // const signOutHandler = (e) => {
  //   e.preventDefault();
  //   setIsAuthenticated(false);
  //   localStorage.clear();
  //   window.location.reload(false);
  // };

  useEffect(() => {
    if (isAuthenticated) {
      const data = localStorage.getItem("User");
      console.log("data: ", JSON.parse(data));
      var ParsedUser = JSON.parse(data);
      setUser(ParsedUser);
    }
  }, [isAuthenticated]);
  return (
    <header id="page-header" className="w-4">
      <div className=" header">
        <Row>
          <Col xs={12} className="mt-4 justify-content-between">
            <div className="d-flex justify-content-between">
              <div className="imageDiv">
                <div className={"d-lg-none"}>
                  <OffCanvas open={open} />
                </div>

                {/* <NavLink className=" mb-auto d-none d-lg-block" to="/"> */}
                  <img src={AppLogo} className="app-logo w-25 mb-auto d-none d-lg-block" alt="" />
                {/* </NavLink> */}
              </div>
              {/* <div className="align-items-end mt-4">
              </div> */}
              <div className="m-4 ">
                {/* display the user name with icon user */}
                <div className="d-flex">
                  <i
                    className="fas fa-user mr-3 p-1"
                    style={{ fontSize: "1.2rem" }}
                  ></i>
                  <h4 className="mr-3">{user.userName}</h4>
                </div>
                {/* {isAuthenticated && (
                  <NavLink className="navbar-brand" onClick={signOutHandler}>
                    <Button variant="outline-success">Sign Out</Button>
                  </NavLink>
                )} */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <hr></hr> */}
    </header>
  );
}

export default Header;
