import React, { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";
import  handleExportToExcel  from "../../../core/utils/exportAsExcel.js";

import Layout from "./../Layout/mainLayout.js";
import SchoolModal from "./../components/Modal/schoolModal.js";
import SpinnerDev from "./../components/Spinner/Spinner.js";
import { Col, Container, Button, Row, Form } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import "../admin.scss";
// import UpdateIcon from "../Icons/updateIcon";
import toast, { Toaster } from "react-hot-toast";

function School() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [schoolData, setSchoolData] = useState({});
  const [filterText, setFilterText] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state for API loading status
  const [toasted, setToasted] = useState("");
  const [toastedMsg, setToastedMsg] = useState("");

  useEffect(() => {
    if (toasted == "success") {
      toast.success(toastedMsg);
      setToasted("");
    } else if (toasted == "error") {
      toast.error("Something went wrong!");
      setToasted("");
    }
    API.getAllSchools().then((res) => {
      setData(res.data);
      setIsLoading(false); // Set loading status to false once data is received
    });
  }, [show]);

  const handleFilter = () => {
    const filteredData = data.filter((item) => {
      const name = item.name.toLowerCase();
      return name.includes(filterText.toLowerCase());
    });

    return filteredData;
  };

  const filteredData = handleFilter();  

  return (
    <Layout>
      {isLoading ? (
        <SpinnerDev />
      ) : (
        <Container className="mb-4">
          {" "}
          <Row className="">
            <Col xs={12} className="d-flex justify-content-end">
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  setShow(true);
                }}
              >
                Add New School
              </Button>
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  handleExportToExcel('schools', filteredData);
                }}
              >
                Export As Excel
                <span className="ml-2">
                  <i className="fas fa-file-excel"></i>
                </span>
              </Button>
            </Col>
            <Col xs={12} className="d-flex justify-content-between">
              <Container>
                <Form className="row">
                  <Form.Group>
                    <Form.Control
                      className="form-control"
                      type="text"
                      placeholder="Filter by name"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Container>
            </Col>
            <Col xs={12}>
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Country Name</th>
                    <th scope="col">State Name</th>
                    <th scope="col">Active</th>
                    <th scope="col">Comments</th>
                    <th scope="col">Rate</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Users</th>
                    <th scope="col">Grades</th>
                    {/* <th scope="col">Package ID</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.name}</td>
                      <td>{item.address}</td>
                      <td>{item.phoneNumber}</td>
                      <td>{item.countryName}</td>
                          <td>{item.stateName}</td>
                          <td> {item.isActive ? (
                              <i className="fas fa-check text-success"></i>
                          ) : (
                              <i className="fas fa-minus text-danger"></i>
                          )} </td>

                      <td>{item.comments || "empty"}</td>
                      <td>{item.rate || "not rated"}</td>
                      <td>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShow(true);
                            setSchoolData(item);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </span>
                      </td>
                      <td>
                        <Link to={`/admin/schools/${item.schoolId}/user`}>
                          <Button variant="outline-success">View Users</Button>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/admin/schools/${item.schoolId}/grade`}>
                          <Button variant="outline-success">View Grades</Button>
                        </Link>
                      </td>
                      {/* <td>{item.packageId}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      )}
      {show && (
        <SchoolModal
          setShow={setShow}
          schoolData={schoolData}
          setSchoolData={setSchoolData}
          setToasted={setToasted}
          setToastedMsg={setToastedMsg}
        />
      )}
      <Toaster position="bottom-right" reverseOrder={false} />{" "}
    </Layout>
  );
}
export default School;
