import { useEffect, useState } from "react";
import API from "../../../../core/services/adminServices.js";

import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function PackageModal({
  setShow,
  packageData,
  setPackageData,
  setToasted,
  setToastedMsg,
}) {
  const [name, setName] = useState(packageData.name || "");
  const [isActive, setIsActive] = useState(packageData.isActive || false);
  const [formLabel, setFormLabel] = useState("Add Package");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (Object.keys(packageData).length !== 0) setFormLabel("Update Package");
  }, [packageData]);

  useEffect(() => {
    setIsFormValid(name.trim().length > 0);
  }, [name]);

  const handleSubmit = () => {
    if (Object.keys(packageData).length === 0) {
      API.addPackage(name, isActive)
        .then(function (response) {
          console.log(response);
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("Package Added Successfully!");
          } else {
            setToasted("error");
          }
          setShow(false);
          setPackageData({});
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    } else {
      API.updatePackage(Number(packageData.packageId), name, isActive)
        .then(function (response) {
          console.log(response);
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("Package Updated Successfully!");
          } else {
            setToasted("error");
          }
          setShow(false);
          setPackageData({});
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShow(false);
          setPackageData({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formLabel}</Modal.Title>
        </Modal.Header>
        <Form className="p-4">
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Package Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              defaultValue={packageData.name ? packageData.name : ""}
              required // Set the input as required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="status">
            <Form.Check
              type="checkbox"
              label="Is Active"
              onChange={(e) => {
                setIsActive(e.target.checked);
              }}
              defaultChecked={packageData.isActive || false}
            />
          </Form.Group>
          <Button variant="success" onClick={handleSubmit} disabled={!isFormValid}>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default PackageModal;
