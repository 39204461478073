import { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";

import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from "./../Layout/mainLayout.js";
import SpinnerDev from "./../components/Spinner/Spinner.js";
import MessageComponent from "./../components/noData/nodata.js";

import UserDetails from "./../components/Modal/details/userDetails.js";
import UserModal from "./../components/Modal/userModal.js";
import UserModalBulk from "./../components/Modal/userModalBulk.js";

import Button from "react-bootstrap/Button";
// import "../../../admin.scss";

function UsersByRoleDetails() {
  const { roleName } = useParams();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [toasted, setToasted] = useState("");
  const [toastedMsg, setToastedMsg] = useState("");
  

  useEffect(() => {
    if (toasted == "success") {
      toast.success(toastedMsg);
      setToasted("");
    } else if (toasted == "error") {
      toast.error("Something went wrong!");
      setToasted("");
    }
      API.getUsersByRoleName(roleName).then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <SpinnerDev />
      ) : (
        <Container className="mb-4">
          <Row className="">
            <Col xs={12}>
              {data.length === 0 ? (
                <MessageComponent
                  message={`There is no users found for this role`}
                />
              ) : (
                <table className="table table-striped border">
                  <thead>
                    <tr>
                      <th scope="col">Full Name</th>
                      {/* <th scope="col">Email</th> */}
                      {/* <th scope="col">Phone Number</th> */}
                      <th scope="col">Active</th>
                      <th scope="col">Edit</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, idx) => (
                      <tr key={idx}>
                        <td>{`${item.firstName} ${item.lastName}`}</td>
                        {/* <td>{item.email}</td> */}
                        {/* <td>{item.phoneNumber}</td> */}
                        <td>
                          {item.isActive ? (
                            <i className="fas fa-check text-success"></i>
                          ) : (
                            <i className="fas fa-minus text-danger"></i>
                          )}
                        </td>
                        <td>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShow(true);
                              setUserData(item);
                            }}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        </td>
                        <td>
                          <Button
                            variant="outline-success"
                            onClick={() => {
                              setShowDetails(true);
                              setUserData(item);
                            }}
                          >
                            View Details
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Col>
          </Row>
        </Container>
      )}
      {show && (
        <UserModal
          setShow={setShow}
          userData={userData}
          setUserData={setUserData}
        />
      )}
      {showDetails && (
        <UserDetails
          setShow={setShowDetails}
          userData={userData}
          setUserData={setUserData}
        />
      )}
    </Layout>
  );
}

export default UsersByRoleDetails;
