import React, { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";
import  handleExportToExcel  from "../../../core/utils/exportAsExcel.js";

import Layout from "./../Layout/mainLayout.js";
import UserModal from "../components/Modal/userModal.js";
import ResetPassword from "../components/Modal/resetPassword.js";
import SpinnerDev from "./../components/Spinner/Spinner.js";
import { Col, Container, Button, Row, Form } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import "../admin.scss";
import UserDetails from "../components/Modal/details/userDetails.js";
import { UserNotActiveIcon } from "./../components/Icons/generalIcons.js";
import toast, { Toaster } from "react-hot-toast";
// import lessonApi from "../../../core/services/adminServices.js";

function User() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [userData, setUserData] = useState({});
  const [filterText, setFilterText] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state for API loading status
  const [toasted, setToasted] = useState("");
  const [toastedMsg, setToastedMsg] = useState("");

  useEffect(() => {
    if (toasted == "success") {
      toast.success(toastedMsg);
      setToasted("")
    } else if (toasted == "error") {
      toast.error(toastedMsg);
      setToasted("")
    }
    API.getAllUsers().then((res) => {
      setData(res.data || []);
      setIsLoading(false); // Set loading status to false once data is received
    });
  }, [show, showReset]);

  const handleFilter = () => {
    const filteredData = data.filter((item) => {
      const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
      const isActive = item.isActive;

      if (filterText && filterActive) {
        return fullName.includes(filterText.toLowerCase()) && isActive;
      } else if (filterText) {
        return fullName.includes(filterText.toLowerCase());
      } else if (filterActive) {
        return isActive;
      } else {
        return true;
      }
    });

    return filteredData;
  };

  const filteredData = handleFilter();

  return (
    <Layout>
      {isLoading ? (
        <SpinnerDev />
      ) : (
        <Container className="mb-4">
          <Row className="">
            <Col xs={12} className="d-flex justify-content-end">
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  setShow(true);
                }}
              >
                Add New User
              </Button>
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  handleExportToExcel('users', filteredData);
                }}
              >
                Export As Excel
                <span className="ml-2">
                  <i className="fas fa-file-excel"></i>
                </span>
              </Button>
            </Col>
            <Col xs={12} className="d-flex justify-content-between">
              <Container>
                <Form className="row">
                  <Form.Group>
                    <Form.Control
                      className="form-control"
                      type="text"
                      placeholder="Filter by name"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        onChange={(e) => setFilterActive(e.target.checked)}
                      />
                      <label className="form-check-label" >
                        Active
                      </label>
                    </div>
                  </Form.Group>
                </Form>
              </Container>
            </Col>
            <Col xs={12}>
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Username</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Role</th>
                    <th scope="col">Active</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Details</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.userName}</td>
                      <td>{`${item.firstName} ${item.lastName}`}</td>
                      <td>{item.roleName}</td>
                      <td>
                        {item.isActive ? (
                          <i className="fas fa-check text-success"></i>
                        ) : (
                          <i className="fas fa-minus text-danger"></i>
                        )}
                      </td>
                      <td>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShow(true);
                            setUserData(item);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </span>
                      </td>
                      <td>
                        <Button
                          variant="outline-success"
                          onClick={() => {
                            setShowDetails(true);
                            setUserData(item);
                          }}
                        >
                          View Details
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-success"
                          onClick={() => {
                            setShowReset(true);
                            setUserData(item);
                          }}
                        >
                          Reset Password
                        </Button>
                      </td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      )}
      {show && (
        <UserModal
          setShow={setShow}
          userData={userData}
          setUserData={setUserData}
          setToasted={setToasted}
          setToastedMsg={setToastedMsg}
        />
      )}
      {showDetails && (
        <UserDetails
          setShow={setShowDetails}
          userData={userData}
          setUserData={setUserData}
        />
      )}
      {showReset && (
        <ResetPassword
          setShow={setShowReset}
          userData={userData}
          setUserData={setUserData}
          setToasted={setToasted}
          setToastedMsg={setToastedMsg}
          admin={true}
        />
      )}
      <Toaster position="bottom-right" reverseOrder={false} />
    </Layout>
  );
}

export default User;
