import React, { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";
import  handleExportToExcel  from "../../../core/utils/exportAsExcel.js";

import Layout from "./../Layout/mainLayout.js";
// import ModalComp from "../components/Modal/modal.js";
import SpinnerDev from "./../components/Spinner/Spinner.js";
import { Col, Container, Button, Row, Form } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import "../admin.scss";
// import UpdateIcon from "../Icons/updateIcon";
function Lesson() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  
  const [filterText, setFilterText] = useState("");
  const [isLoading, setIsLoading] = useState(true); // New state for API loading status
  
  
  useEffect(() => {
    API.getAllLessons().then((res) => {
      setData(res.data);
      setIsLoading(false); // Set loading status to false once data is received
    });

  }, [show]);

  const handleFilter = () => {
    const filteredData = data.filter((item) => {
      const name = item.name.toLowerCase();
        return name.includes(filterText.toLowerCase());
    });

    return filteredData;
  };

  const filteredData = handleFilter();

  return (
    <Layout>
      {/* <div> */}
      {isLoading ? <SpinnerDev /> : 
      <Container className="mb-4">
      <Row className="">
          <Col xs={12} className="d-flex justify-content-end">
            {/* <Button
            className="m-3"
              variant="success"
              onClick={() => {
                setShow(true);
              }}
            >
              Add New User
            </Button> */}
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  handleExportToExcel('lessons', filteredData);
                }}
              >
                Export As Excel
                <span className="ml-2">
                  <i className="fas fa-file-excel"></i>
                </span>
              </Button>
          </Col>
                      <Col xs={12} className="d-flex justify-content-between">
              <Container>
                <Form className="row">
                  <Form.Group>
                    <Form.Control
                      className="form-control"
                      type="text"
                      placeholder="Filter by name"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Container>
            </Col>
            <Col xs={12}>
            <table className="table table-striped border">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Objective Name</th>
                  <th scope="col " className="d-none d-md-block">Video Name</th>
                  {/* <th scope="col">Edit</th> */}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, idx) => (
                  <tr key={idx}>
                    <td>{item.name}</td>
                    <td>{item.objectiveName}</td>
                    <td className="d-none d-md-block">{item.videoName}</td>
                    {/* <td>
                      <span>
                        <Link to={`/admin/lessons/${item.lessionId}`}>
                          <i className="fas fa-edit"></i>
                        </Link>
                      </span>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </Col> 
        </Row>
        </Container>}
        
      {/* </div> */}
      {/* {show && <ModalComp setShow={setShow} />} */}
    </Layout>
  );
}
export default Lesson;