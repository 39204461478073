import { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";
import  handleExportToExcel  from "../../../core/utils/exportAsExcel.js";

import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from "./../Layout/mainLayout.js";
import SpinnerDev from "./../components/Spinner/Spinner.js";
import MessageComponent from "./../components/noData/nodata.js";

import UserDetails from "./../components/Modal/details/userDetails.js";
import UserModal from "./../components/Modal/userModal.js";
import UserModalBulk from "./../components/Modal/userModalBulk.js";

import toast, { Toaster } from "react-hot-toast";


import Button from "react-bootstrap/Button";
// import "../../../admin.scss";

function UsersBySchoolIdDetails() {
  const { schoolId } = useParams();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [toasted, setToasted] = useState("");
  const [toastedMsg, setToastedMsg] = useState("");
  

  useEffect(() => {
    if (toasted == "success") {
      toast.success(toastedMsg);
      setToasted("");
    } else if (toasted == "error") {
      toast.error("Something went wrong!");
      setToasted("");
    }
      API.getUsersBySchoolId(schoolId).then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
    
  }, [show]);

  return (
    <Layout>
      {isLoading ? (
        <SpinnerDev />
      ) : (
        <Container className="mb-4">
          <Row className="">
          <Col xs={12} className="d-flex justify-content-end">
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  handleExportToExcel(`UsersBySchool-${schoolId}`, data);
                }}
              >
                Export As Excel
                <span className="ml-2">
                  <i className="fas fa-file-excel"></i>
                </span>
              </Button>
            </Col>
            <Col xs={12}>
              {data.length === 0 ? (
                <MessageComponent
                  message={`There is no users found for this school`}
                />
              ) : (
                <table className="table table-striped border">
                  <thead>
                    <tr>
                      <th scope="col">Full Name</th>
                      <th scope="col">Grade</th>
                      <th scope="col">Section</th>
                      <th scope="col">Active</th>
                      <th scope="col">Edit</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, idx) => (
                      <tr key={idx}>
                        <td>{`${item.firstName} ${item.lastName}`}</td>
                        <td>{item.gradeName}</td> 
                        <td>{item.sectionName}</td>
                        <td>
                          {item.isActive ? (
                            <i className="fas fa-check text-success"></i>
                          ) : (
                            <i className="fas fa-minus text-danger"></i>
                          )}
                        </td>
                        <td>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShow(true);
                              setUserData(item);
                            }}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        </td>
                        <td>
                          <Button
                            variant="outline-success"
                            onClick={() => {
                              setShowDetails(true);
                              setUserData(item);
                            }}
                          >
                            View Details
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Col>
          </Row>
        </Container>
      )}
      {show && (
        <UserModal
          setShow={setShow}
          userData={userData}
          setUserData={setUserData}
          setToasted={setToasted}
          setToastedMsg={setToastedMsg}
        />
      )}
      {showDetails && (
        <UserDetails
          setShow={setShowDetails}
          userData={userData}
          setUserData={setUserData}
        />
      )}
      <Toaster position="bottom-right" reverseOrder={false} />{" "}
    </Layout>
  );
}

export default UsersBySchoolIdDetails;
