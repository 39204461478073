import React from 'react';

export default class ErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null
        }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error: error };
    }
    componentDidCatch(error, errorInfo) {
        console.log(error)
    }
    render() {
        if (this.state.hasError) {
            return <>Error</>
        } else {
            return this.props.children;
        }
    }
}