import React, { useState, useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import AppLogo from "../../resource/img/happy-math-logo.svg";
import UnderLay from "../../resource/img/underlay.png";
import { NavLink } from "react-router-dom";
import avatar from "../../resource/img/child-icon.png";
import "./menu.scss";
import { useLocation } from "react-router-dom";
import OffCanvas from "./../../../src/pages/admin/components/Sidebar/offCanvas";
import { Container, Row, Col } from "react-bootstrap";

function Menu(props) {
  const [name, setName] = useState("User");
  const [isAuthenticated, setisAuthenticated] = useState(
    localStorage.getItem("isAuthenticated")
  );
  const [role, setRole] = useState(localStorage.getItem("Role"));
  const adminLocation = useLocation().pathname.includes("/admin");

  const admin_role = process.env.REACT_APP_ADMIN_ROLE;

  useEffect(() => {
    if (isAuthenticated) {
      const data = localStorage.getItem("User");
      console.log("data: ", JSON.parse(data));
      var ParsedUser = JSON.parse(data);
      const name = ParsedUser.userName;
      setName(name);
    }
    if (role === admin_role) {
      if (!adminLocation) {
        window.location.href = "/admin";
      }
    } else {
      if (adminLocation) {
        window.location.href = "/";
      }
    }
  }, [isAuthenticated]);

  const signoutHandler = (e) => {
    debugger;
    e.preventDefault();
    setisAuthenticated(false);
    localStorage.clear();
    window.location.reload(false);
  };

  return (
    <>

        <nav className="navbar navbar-expand-lg navbar-light bg-green fixed-top">
          <img src={UnderLay} className="underlay" alt=""></img>
          <NavLink className="navbar-brand ml-5" to="/">
            <img src={AppLogo} className="app-logo" alt="" />
          </NavLink>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          {role === "Admin" && (<div className={"d-lg-none"}>
            <OffCanvas open={open} />
          </div>)}

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-md-auto">
              <li className="nav-item dropdown px-lg-5 d-none d-lg-block d-flex">
                {/* <a
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                > */}
                <div className="user">
                  <span className="user-name mr-1 mt-2">{`Welcome back ${name}`}</span>{" "}
                  {/* <br /> */}
                  {/* <span className="user-info">
                      Last loggedin Apr 26, 2020, 10:10:10
                    </span> */}
                </div>
                {/* <img className="icon ml-2 rounded-circle" src={avatar} /> */}
                {/* </a> */}

                {/* <div
                  className="dropdown-menu dropdown-menu-lg-right"
                  aria-labelledby="navbarDropdown"
                >
                  <NavLink className="nav-link" to="/admin">
                    Settings
                  </NavLink> */}

                {/* </div> */}
              </li>
              {isAuthenticated && (
                <a href="#" className="nav-link" onClick={signoutHandler}>
                  <span className=" font-weight-bold text-white border border-green p-2 rounded bottom-3">
                    Sign Out
                  </span>
                </a>
              )}
            </ul>
          </div>
        </nav>

    </>
  );
  return (
    <Container>
      <Row>
        <Col>
          <div className="navbar navbar-expand-lg navbar-light bg-green fixed-top d-flex justify-content-between">
            <img src={UnderLay} className="underlay" alt=""></img>
            <NavLink
              className="navbar-brand ml-5"
              to={role === "Admin" ? "/admin" : "/"}
            >
              <img src={AppLogo} className="app-logo" alt="" />
            </NavLink>

            <div className="user ">
              <span className="user-name mr-1 mt-2 d-none d-lg-flex">{`Welcome back ${name}`}</span>{" "}
              {isAuthenticated && (
                <a
                  href="#"
                  className="nav-link d-none d-lg-flex"
                  onClick={signoutHandler}
                >
                  <span className=" font-weight-bold text-white border border-green p-2 rounded bottom-3">
                    Sign Out
                  </span>
                </a>
              )}
              {role === "Admin" && (
                <div className={"d-lg-none"}>
                  <OffCanvas open={open} />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Menu;
