import React from 'react';
import PropTypes from 'prop-types';
import {IntlProvider} from 'react-intl';
import en_us from './translations/en-us.json';

const getAllLanguages = () => ([
    {
        name:'English',
        id: 'en_us'
    }
]);

const getDefaultLanguage = () => getAllLanguages()[0];

const messages = {
    'en_us' : en_us
};

const getCurrentLanguage = (langId) =>{
    if(langId){
        const allLanguages = getAllLanguages();
        for (const index in allLanguages) {
            const lang = allLanguages[index];
            if(lang.id = langId){
                return langId;
            }
        }
    }
    return getDefaultLanguage().id;
}

const LocalizationProvider = ({intl, ...props}) =>{
    return(
        <IntlProvider
            locale= {getCurrentLanguage(props.langId)}
            messages={messages[getCurrentLanguage(props.langId)]}
            defaultLanguage={getCurrentLanguage()}>
                {props.children}
            </IntlProvider>
    )
}

LocalizationProvider.propTypes ={
    langId : PropTypes.string.isRequired
}

export {
    getAllLanguages,
    getDefaultLanguage,
    LocalizationProvider
}

