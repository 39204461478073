import { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";
import  handleExportToExcel  from "../../../core/utils/exportAsExcel.js";

import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Layout from "./../Layout/mainLayout.js";
import SpinnerDev from "./../components/Spinner/Spinner.js";
import MessageComponent from "./../components/noData/nodata.js";

import UserDetails from "./../components/Modal/details/userDetails.js";
import UserModal from "./../components/Modal/userModal.js";
import UserModalBulk from "./../components/Modal/userModalBulk.js";
import { BackArrow } from "../components/Icons/generalIcons.js";
import toast, { Toaster } from "react-hot-toast";


import Button from "react-bootstrap/Button";
// import "../../../admin.scss";

function UsersBySectionIdDetails() {
  const { sectionId } = useParams();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showBulk, setShowBulk] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [toasted, setToasted] = useState("");
  const [toastedMsg, setToastedMsg] = useState("");
    const [alermsg, setalermsg] = useState("");

  useEffect(() => {
    if (toasted == "success") {
      toast.success(toastedMsg);
      setToasted("");
    } else if (toasted == "error") {
      toast.error("Something went wrong!");
      setToasted("");
    }
      API.getUsersBySectionId(sectionId).then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
  }, [show, showBulk]);

  useEffect(() => {
      setIsLoading(true);
      API.getUsersBySectionId(sectionId).then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
  }, [showBulk]);
    const renderMessage = () =>
        alermsg  !== ""&&
        (
            <div className="alert alert-danger" role="alert">{alermsg}</div>
        );
  return (
    <Layout>
      {isLoading ? (
        <SpinnerDev />
      ) : (
                  <Container className="mb-4">
                      {renderMessage("uname")}
          <Row className="">
              <Col xs={12} className="d-flex justify-content-end">
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  setShowBulk(true);
                }}
              >
                Import Users from file 
              </Button>
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  handleExportToExcel(`UsersBySectionId-${sectionId}`, data);
                }}
              >
                Export As Excel
                <span className="ml-2">
                  <i className="fas fa-file-excel"></i>
                </span>
              </Button>
            </Col>
            <Col xs={12}>
              {data.length === 0 ? (
                <MessageComponent
                  message={`There is no users found for this section`}
                />
                              ) : (

                <table className="table table-striped border">
                  <thead>
                    <tr>
                      <th scope="col">Full Name</th>
                      {/* <th scope="col">Email</th> */}
                      {/* <th scope="col">Phone Number</th> */}
                      <th scope="col">Active</th>
                      <th scope="col">Edit</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, idx) => (
                      <tr key={idx}>
                        <td>{`${item.firstName} ${item.lastName}`}</td>
                        {/* <td>{item.email}</td> */}
                        {/* <td>{item.phoneNumber}</td> */}
                        <td>
                          {item.isActive ? (
                            <i className="fas fa-check text-success"></i>
                          ) : (
                            <i className="fas fa-minus text-danger"></i>
                          )}
                        </td>
                        <td>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShow(true);
                              setUserData(item);
                            }}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        </td>
                        <td>
                          <Button
                            variant="outline-success"
                            onClick={() => {
                              setShowDetails(true);
                              setUserData(item);
                            }}
                          >
                            View Details
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Col>
          </Row>
        </Container>
      )}
      {show && (
        <UserModal
                  setShow={setShow}
                  userData={userData}
                  setUserData={setUserData}
                  setToasted={setToasted}
                  setToastedMsg={setToastedMsg}
                 
                  
        />
      )}
      {showBulk && (
        <UserModalBulk
          setShowBulk={setShowBulk}
          sectionId={sectionId}
          setToasted={setToasted}
          setToastedMsg={setToastedMsg}
          setalermsg={setalermsg}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {showDetails && (
        <UserDetails
          setShow={setShowDetails}
          userData={userData}
          setUserData={setUserData}
        />
      )}
          <Toaster position="bottom-right" reverseOrder={false} />{" "}
        
    </Layout>
  );
}

export default UsersBySectionIdDetails;
