import { useEffect, useState } from "react";
import API from "../../../../core/services/adminServices.js";

import { Form, FormLabel } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function OrgModal({ setShow, orgData, setOrgData, setToasted, setToastedMsg }) {
  const [name, setName] = useState(orgData.name || "");
  const [description, setDescription] = useState(orgData.description || "");
  const [isActive, setIsActive] = useState(orgData.isActive || false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [formLabel, setFormLabel] = useState("Add Organization");

  useEffect(() => {
    if (Object.keys(orgData).length !== 0) setFormLabel("Update Organization");
  }, [orgData]);

  useEffect(() => {
    setIsFormValid(name.trim().length > 0 && description.trim().length > 0);
  }, [name, description]);

  const handleSubmit = () => {
    if (Object.keys(orgData).length === 0) {
      API.addOrganization(name, description)
        .then(function (response) {
          console.log(response);
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("Organization Added Successfully!");
          } else {
            setToasted("error");
          }
          setShow(false);
          setOrgData({});
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    } else {
      API.updateOrganization(Number(orgData.organizationId), name, description, isActive)
        .then(function (response) {
          console.log(response);
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("Organization Updated Successfully!");
          } else {
            setToasted("error");
          }
          setShow(false);
          setOrgData({});
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShow(false);
          setOrgData({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formLabel}</Modal.Title>
        </Modal.Header>
        <Form className="p-4">
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Organization Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              defaultValue={orgData.name ? orgData.name : ""}
              required // Set the input as required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Organization Description"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              defaultValue={orgData.description ? orgData.description : ""}
              required // Set the input as required
            />
          </Form.Group>
          {Object.keys(orgData).length !== 0 && (
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Active"
                checked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                }}
              />
            </Form.Group>
          )}

          <Button variant="success" onClick={handleSubmit} disabled={!isFormValid}>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default OrgModal;
