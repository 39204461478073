import axios from 'axios';

const requestInterceptor = async (request) => {
    // Check if the token is valid and not expired
    // const token = localStorage.getItem('lmstoken');
    // const tokenExpiration = localStorage.getItem('tokenExpiration');
  
    // if (token && tokenExpiration && new Date(tokenExpiration) > new Date()) {
    //   // Token is valid, add it to the request headers
    //   request.headers['Authorization'] = `Bearer ${token}`;
    // } else {
    //   // Token is expired or not available, refresh the token
    //   try {
    //     const refreshedToken = await refreshToken(); // Call your refresh token function to get a new token
    //     request.headers['Authorization'] = `Bearer ${refreshedToken}`;
    //   } catch (error) {
    //     // Handle token refresh error
    //     console.error('Failed to refresh token:', error);
    //   }
    // }
  
    return request;
  };

const requestErrorHandler = (error)=>{
    Promise.reject(error);
}

const responseInterceptor = (response)=>{
    return response;
}

const responseErrorHandler = (error) =>{
    Promise.reject(error);
}

export default{
    configure : ()=>{
        axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
        axios.interceptors.request.use(requestInterceptor,requestErrorHandler);
        axios.interceptors.response.use(responseInterceptor,responseErrorHandler);
    }
}