import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
// import "../../../admin.scss";

function UserDetails({ setShow, userData, setUserData }) {
  console.log("LLL :", userData);
  return (
    <>
      <Modal
        className="modal-dialog modal-dialog-centered"
        show={true}
        onHide={() => {
          setShow(false);
          setUserData({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>User Information</Modal.Title>
        </Modal.Header>
        {/* <Container>
          <Row>
            <Col xs={12} className="d-flex justify-content-between mt-4">
              <span>{userData.userRoles}</span>
              <h2>
                {userData.firstName} {userData.lastName}
              </h2>
              <span
                className={userData.isActive ? "greenDot" : "redDot"}
              ></span>
            </Col>
            <Col xs={12} className=" text-center border-bottom mb-4">
              <h5>{userData.userName}</h5>
            </Col>
            <Col xs={12} className="d-flex justify-content-between">
              <h5>Email:</h5>
              <div className="d-flex">
                <p className="verify">{userData.email}</p>
                <span>
                  <i
                    className="fas fa-certificate"
                    style={
                      userData.emailConfirmed
                        ? { color: "green" }
                        : { color: "gray" }
                    }
                  ></i>
                </span>
              </div>
            </Col>
            <Col
              xs={12}
              className="d-flex justify-content-between border-bottom mb-4"
            >
              <h5>Phone Number:</h5>
              <div className="d-flex">
                <p className="verify">{userData.phoneNumber}</p>
                <span>
                  <i
                    className="fas fa-certificate"
                    style={
                      userData.phoneNumberConfirmed
                        ? { color: "green" }
                        : { color: "gray" }
                    }
                  ></i>
                </span>
              </div>
            </Col>
          </Row>
        </Container> */}
        <Container>
          {/* <div className="card m-2"> */}
          <div className="card-body">
            <div className="row">
              <div className="col-3 m-auto">
                <img
                  src="https://bootdey.com/img/Content/avatar/avatar7.png"
                  alt="Admin"
                  className="w-100"
                />
              </div>
              <div className=" col-9">
                <p>
                  <i className="fa" style={{ color: "black" }}>
                    Name : {userData.firstName} {userData.lastName}
                  </i>
                </p>
                <div className="row">
                  <div className=" col-12">
                    <p>
                      <i className="fa" style={{ color: "black" }}>
                        School : {userData.schoolName || "not set"}
                      </i>
                    </p>
                  </div>
                  <div className=" col-12">
                    <p>
                      <i className="fa" style={{ color: "black" }}>
                        Grade : {userData.gradeName || "not set"}
                      </i>
                    </p>
                  </div>
                  <div className=" col-12">
                    <p>
                      <i className="fa" style={{ color: "black" }}>
                        Section : {userData.sectionName || "not set"}
                      </i>
                    </p>
                  </div>

                  <div className=" col-12">
                    <p>
                      <i className="fa fa-user" style={{ color: "black" }}>
                        <span className="ml-2">{userData.userName}</span>
                      </i>
                    </p>
                  </div>
                  <div className=" col-12">
                    <p>
                      <a href="mailto:">
                        <i
                          className="fa fa-envelope"
                          style={{ color: "black" }}
                        >
                          {" "}
                          <span className="ml-2">{userData.email}</span>
                        </i>
                      </a>
                    </p>
                  </div>
                  <div className=" col-12">
                    <p>
                      <a href="tel:">
                        <i className="fa fa-phone" style={{ color: "black" }}>
                          <span className="ml-2">{userData.phoneNumber}</span>
                        </i>
                      </a>
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span
                        className={
                          userData.isActive
                            ? "badge badge-success p-1 w-100"
                            : "badge badge-danger p-1 w-100"
                        }
                      >
                        <i className="fa fa-cog"></i>{" "}
                        {userData.isActive ? "Active" : "Inactive"}
                      </span>
                    </p>
                  </div>
                  {/* role name */}
                  <div className=" col-6">
                    <p>
                      <span className="badge badge-primary p-1 w-100">
                        <i className="fa fa-user"></i> {userData.roleName}
                      </span>
                    </p>
                  </div>
                </div>
                {/* <hr /> */}
                {/* <span className="badge badge-primary"><i className="fa fa-user"></i> Admin</span> */}
                {/* <span className={userData.isActive ? "badge badge-success p-1" : "badge badge-danger p-1" }><i className="fa fa-cog"></i> {userData.isActive ? "Active" : "UnActive" }</span> */}
                {/* <span className="badge badge-danger"><i className="fa fa-envelope"></i> Email verified</span> */}
              </div>
            </div>
          </div>
          {/* </div> */}
        </Container>
      </Modal>
    </>
  );
}

export default UserDetails;



// {
//   "schoolId": 1,
//   "schoolName": "Default School 1",
// },