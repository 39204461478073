import { useEffect, useState } from "react";
import API from "../../../../core/services/adminServices.js";

import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function RoleModal({ setShow, roleData, setRoleData, setToasted, setToastedMsg }) {
  const [name, setName] = useState(roleData.roleName || "");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(name.trim().length > 0);
  }, [name]);

  const handleSubmit = () => {
    if (Object.keys(roleData).length === 0) {
      API.addRole(name)
        .then(function (response) {
          console.log(response);
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("Role Added Successfully!");
          } else {
            setToasted("error");
          }
          setShow(false);
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    } else {
      // update Role here
      setShow(false);
      setRoleData({});
      console.log("DATA :", roleData);
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShow(false);
          setRoleData({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>
        <Form className="p-4">
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Role Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              defaultValue={roleData.roleName ? roleData.roleName : ""}
              required // Set the input as required
            />
          </Form.Group>
          <Button variant="success" onClick={handleSubmit} disabled={!isFormValid}>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default RoleModal;
