
const MessageComponent = ({message}) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "55vh" }}>
        <div className="m-5" >
            <h1>{message}</h1>
        </div>
    </div>
  );
};

export default MessageComponent;
