import React, { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";
import  handleExportToExcel  from "../../../core/utils/exportAsExcel.js";


import Layout from "./../Layout/mainLayout.js";
import SchoolModal from "./../components/Modal/schoolModal.js";
import SpinnerDev from "./../components/Spinner/Spinner.js";
import { Col, Container, Button, Row, Form } from "react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import "../admin.scss";
// import UpdateIcon from "../Icons/updateIcon";
import toast, { Toaster } from "react-hot-toast";

function OrgSchoolsDetails() {
  const { organizationId } = useParams();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [schoolData, setSchoolData] = useState({});
  const [filterText, setFilterText] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state for API loading status
  const [toasted, setToasted] = useState("");


  useEffect(() => {
    if (toasted == "success") {
      toast.success("Your transaction was successful!");
    } else if (toasted == "error") {
      toast.error("Something went wrong!");
    }
      API.getAllSchoolsByOrganizationId(organizationId).then((res) => {
        setData(res.data);
        setIsLoading(false); // Set loading status to false once data is received
      });

  }, [show]);

  const handleFilter = () => {
    const filteredData = data.filter((item) => {
      const name = item.name.toLowerCase();
      return name.includes(filterText.toLowerCase());
    });

    return filteredData;
  };

  const filteredData = handleFilter();

  return (
    <Layout>
      {isLoading ? (
        <SpinnerDev />
      ) : (
        <Container className="mb-4">
          {" "}
          <Row className="">
          <Col xs={12} className="d-flex justify-content-end">
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  handleExportToExcel('organization-school', filteredData);
                }}
              >
                Export As Excel
                <span className="ml-2">
                  <i className="fas fa-file-excel"></i>
                </span>
              </Button>
            </Col>
            <Col xs={12}>
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Details</th>
                    {/* <th scope="col">Organization ID</th> */}
                    {/* <th scope="col">Package ID</th> */}
                    {/* <th scope="col">Edit</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.name}</td>
                      <td>{item.address}</td>
                      <td>{item.phoneNumber}</td>
                      <td>
                        <Link to={`/admin/schools/${item.schoolId}/user`}>
                          <Button variant="outline-success">
                            View Details
                          </Button>
                        </Link>
                      </td>
                      {/* <td>{item.organizationId}</td> */}
                      {/* <td>{item.packageId}</td> */}
                      {/* <td>
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShow(true);
                          setSchoolData(item);
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </span>
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      )}
      {show && (
        <SchoolModal
          setShow={setShow}
          schoolData={schoolData}
          setSchoolData={setSchoolData}
          setToasted={setToasted}
        />
      )}
      <Toaster position="bottom-right" reverseOrder={false} />{" "}
    </Layout>
  );
}
export default OrgSchoolsDetails;