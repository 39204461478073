import * as XLSX from "xlsx"; // Update the import statement

// Function to export data as Excel
const handleExportToExcel = (tableName, data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SchoolData");

    // get the current date as a string with format YYYY-MM-DD-HH-mm-ss
    const date = new Date().toJSON().slice(0, 19).replace(/[-T]/g, "");
    XLSX.writeFile(workbook, `${tableName}-${date}.xlsx`);
    };

export default handleExportToExcel;