const UserOption = (users) => {
  // return {
  //   title: {
  //     text: `Total Users : ${users.total ? users.total : 0}`,
  //   },
  //   animationEnabled: true,
  //   exportEnabled: true,
  //   data: [
  //     {
  //       type: "pie",
  //       startAngle: -30,
  //       toolTipContent: "{y} {indexLabel} - #percent %",
  //       dataPoints: [
  //         {
  //           y: users.inactive,
  //           indexLabel: `${users.inactive > 0 ? "Inactive Users" : ""}`,
  //           color: "#F6F7C1",
  //         },
  //         {
  //           y: users.active,
  //           indexLabel: `${users.active > 0 ? "Active Users" : ""}`,
  //           color: "#BEF0CB",
  //         },
  //       ],
  //     },
  //   ],
  // };
  return {
    series: [users.inactive, users.active],
    options: {
      
      colors: ["#FFD700", "#a5ca41"],
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Inactive Users", "Active Users"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 400,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
};

const SchoolsOption = (school) => {
  // return {
  //   title: {
  //     text: `Total Schools : ${school.total ? school.total : 0}`,
  //   },
  //   animationEnabled: true,
  //   exportEnabled: true,
  //   data: [
  //     {
  //       type: "doughnut",
  //       startAngle: -50,
  //       toolTipContent: "{y} {indexLabel} - #percent %",
  //       dataPoints: [
  //         {
  //           y: school.inactive,
  //           indexLabel: `${school.inactive > 0 ? "Inactive Schools" : ""}`,
  //           color: "#F6F7C1",
  //         },
  //         {
  //           y: school.active,
  //           indexLabel: `${school.active > 0 ? "Active Schools" : ""}`,
  //           color: "#BEF0CB",
  //         },
  //       ],
  //     },
  //   ],
  // };
  return {
    series: [school.inactive, school.active],
    options: {
      
      colors: ["#FFD700", "#a5ca41"],
      chart: {
        width: 380,
        type: "donut",
      },
      labels: ["Inactive Schools", "Active Schools"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 400,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
};

const OrganizationOption = (org) => {
  // return {
  //   title: {
  //     text: `Total Organizations : ${org.total ? org.total : 0}`,
  //   },
  //   animationEnabled: true,
  //   exportEnabled: true,

  //   data: [
  //     {
  //       type: "doughnut",
  //       startAngle: -50,
  //       toolTipContent: "{y} {indexLabel} - #percent %",
  //       dataPoints: [
  //         {
  //           y: org.inactive,
  //           indexLabel: `${org.inactive > 0 ? "Inactive Organization" : ""}`,
  //           color: "#F6F7C1",
  //         },
  //         {
  //           y: org.active,
  //           indexLabel: `${org.active > 0 ? "Active Organization" : ""}`,
  //           color: "#BEF0CB",
  //         },
  //       ],
  //     },
  //   ],
  // };
  return {
    series: [org.inactive, org.active],
    options: {
      colors: ["#FFD700", "#a5ca41"],
      chart: {
        width: 380,
        type: "donut",
      },
      labels: ["Inactive Organization", "Active Organization"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 400,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
};

const PackageOption = (pack) => {
  // return {
  //   title: {
  //     text: `Total Organizations : ${pack.total ? pack.total : 0}`,
  //   },
  //   animationEnabled: true,
  //   exportEnabled: true,

  //   data: [
  //     {
  //       type: "pie",
  //       startAngle: -50,
  //       toolTipContent: "{y} {indexLabel} - #percent %",
  //       dataPoints: [
  //         {
  //           y: pack.inactive,
  //           indexLabel: `${pack.inactive > 0 ? "Inactive Packages" : ""}`,
  //           color: "#F6F7C1",
  //         },
  //         {
  //           y: pack.active,
  //           indexLabel: `${pack.active > 0 ? "Active Packages" : ""}`,
  //           color: "#BEF0CB",
  //         },
  //       ],
  //     },
  //   ],
  // };
  return {
    series: [pack.inactive, pack.active],
    options: {
      colors: ["#FFD700", "#a5ca41"],
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Inactive Packages", "Active Packages"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 400,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
};

export { UserOption, SchoolsOption, OrganizationOption, PackageOption };