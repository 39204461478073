import React from 'react';
import L from 'react-loadable';

const LoadingComponent = (props)=>{
    if(props.error){
        return <div>Error</div>
    }else if(props.pastDelay) {
        return <div>Loading...</div>
    }else{
        return null;
    }
}

const Loadable = opts =>L({
    loading: LoadingComponent,
    delay:500,
    ...opts
})

export default Loadable;