import React, { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";
import  handleExportToExcel  from "../../../core/utils/exportAsExcel.js";

import Layout from "./../Layout/mainLayout.js";
import RoleModal from "../components/Modal/roleModal.js";
import UsersByRoleDetails from "./usersByRoleDetails.js";
import SpinnerDev from "./../components/Spinner/Spinner.js";
import MessageComponent from "./../components/noData/nodata.js";
import { Col, Container, Button, Row } from "react-bootstrap";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import "../admin.scss";
// import UpdateIcon from "../Icons/updateIcon";
import toast, { Toaster } from "react-hot-toast";

function Role() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [roleData, setRoleData] = useState({});
  const [isLoading, setIsLoading] = useState(true); // New state for API loading status
  const [toasted, setToasted] = useState("");
  const [toastedMsg, setToastedMsg] = useState("");

  useEffect(() => {
    if (toasted == "success") {
      toast.success(toastedMsg);
      setToasted("");
    } else if (toasted == "error") {
      toast.error("Something went wrong!");
      setToasted("");
    }
    API.getAllRoles().then((res) => {
      setData(res.data);
      setIsLoading(false); // Set loading status to false once data is received
    });
  }, [show]);
  return (
    <Layout>
      {isLoading ? (
        <SpinnerDev />
      ) : (
        <Container className="mb-4">
          {" "}
          <Row className="">
            <Col xs={12} className="d-flex justify-content-end">
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  setShow(true);
                }}
              >
                Add New Role
              </Button>
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  handleExportToExcel('roles', data);
                }}
              >
                Export As Excel
                <span className="ml-2">
                  <i className="fas fa-file-excel"></i>
                </span>
              </Button>
            </Col>
            <Col xs={12}>
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Users</th>
                    {/* <th scope="col">Edit</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.roleName}</td>
                      <td>
                        <Link to={`/admin/roles/${item.roleName}/user`}>
                          <Button variant="outline-success">
                            View Users
                          </Button>
                        </Link>
                      </td>
                      {/* <td>
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShow(true);
                          setRoleData(item);
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </span>
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      )}
      {show && (
        <RoleModal
          setShow={setShow}
          roleData={roleData}
          setRoleData={setRoleData}
          setToasted={setToasted}
          setToastedMsg={setToastedMsg}
        />
      )}
      <Toaster position="bottom-right" reverseOrder={false} />{" "}
    </Layout>
  );
}
export default Role;
