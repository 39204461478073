import { useEffect, useState } from "react";
import API from "../../../../core/services/adminServices.js";

import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function GradeModal({
  schoolId,
  setShow,
  gradeData,
  setGradeData,
  setToasted,
  setToastedMsg,
}) {
    const [name, setName] = useState(gradeData.name || "");
    const [isActive, setIsActive] = useState(gradeData.isActive || false);
  const [formLabel, setFormLabel] = useState("Add Grade");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (Object.keys(gradeData).length !== 0) setFormLabel("Update Grade");
  }, [gradeData]);

  useEffect(() => {
    setIsFormValid(name.trim().length > 0);
  }, [name]);

  const handleSubmit = () => {
      if (Object.keys(gradeData).length === 0) {
          API.addGrade(name, schoolId, isActive)
        .then(function (response) {
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("Grade Added Successfully!");
          } else {
            setToasted("error");
          }
          setShow(false);
          setGradeData({});
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    } else {
        API.updateGrade(gradeData.gradeId, name, isActive)
        .then(function (response) {
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("Grade Updated Successfully!");
          } else {
            setToasted("error");
          }
          setShow(false);
          setGradeData({});
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShow(false);
          setGradeData({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formLabel}</Modal.Title>
        </Modal.Header>
        <Form className="p-4">
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Grade Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              defaultValue={gradeData.name ? gradeData.name : ""}
              required // Set the input as required
            />
                  </Form.Group>
                  {Object.keys(gradeData).length !== 0 && (
                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check
                              type="checkbox"
                              label="Active"
                              checked={isActive}
                              onChange={(e) => {
                                  setIsActive(e.target.checked);
                              }}
                          />
                      </Form.Group>
                  )}
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!isFormValid} // Disable the button if the form is not valid
          >
            {Object.keys(gradeData).length === 0 ? "Submit" : "Update"}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default GradeModal;
