import React, { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";
import  handleExportToExcel  from "../../../core/utils/exportAsExcel.js";

import Layout from "../Layout/mainLayout.js";
import PackageModal from "../components/Modal/packageModal.js";
import SpinnerDev from "../components/Spinner/Spinner.js";
import { Col, Container, Button, Row, Form } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import "../admin.scss";
// import UpdateIcon from "../Icons/updateIcon";
import toast, { Toaster } from "react-hot-toast";

function Package() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [packageData, setPackageData] = useState({});
  const [filterText, setFilterText] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state for API loading status
  const [toasted, setToasted] = useState("");
  const [toastedMsg, setToastedMsg] = useState("");

  useEffect(() => {
    if (toasted == "success") {
      toast.success(toastedMsg);
      setToasted("");
    } else if (toasted == "error") {
      toast.error("Something went wrong!");
      setToasted("");
    }
    API.getAllPackages().then((res) => {
      setData(res.data);
      setIsLoading(false); // Set loading status to false once data is received
    });
  }, [show]);

  const handleFilter = () => {
    const filteredData = data.filter((item) => {
      const name = item.name.toLowerCase();
      const isActive = item.isActive;

      if (filterText && filterActive) {
        return name.includes(filterText.toLowerCase()) && isActive;
      } else if (filterText) {
        return name.includes(filterText.toLowerCase());
      } else if (filterActive) {
        return isActive;
      } else {
        return true;
      }
    });

    return filteredData;
  };

  const filteredData = handleFilter();

  return (
    <Layout>
      {isLoading ? (
        <SpinnerDev />
      ) : (
        <Container className="mb-4">
          {" "}
          <Row className="">
            <Col xs={12} className="d-flex justify-content-end">
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  setShow(true);
                }}
              >
                Add New Package
              </Button>
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  handleExportToExcel('packages', filteredData);
                }}
              >
                Export As Excel
                <span className="ml-2">
                  <i className="fas fa-file-excel"></i>
                </span>
              </Button>
            </Col>
            <Col xs={12} className="d-flex justify-content-between">
              <Container>
                <Form className="row">
                  <Form.Group>
                    <Form.Control
                      className="form-control"
                      type="text"
                      placeholder="Filter by name"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        onChange={(e) => setFilterActive(e.target.checked)}
                      />
                      <label className="form-check-label" for="gridCheck">
                        Active
                      </label>
                    </div>
                  </Form.Group>
                </Form>
              </Container>
            </Col>
            <Col xs={12}>
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Active</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Add Lessons</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.name}</td>
                      <td>
                        {item.isActive ? (
                          <i className="fas fa-check text-success"></i>
                        ) : (
                          <i className="fas fa-minus text-danger"></i>
                        )}
                      </td>
                      <td>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShow(true);
                            setPackageData(item);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </span>
                      </td>
                      <td>
                        <Link
                          to={`/admin/packages/${item.packageId}/add-lesson`}
                        >
                          <Button variant="outline-success">Add Lessons</Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      )}
      {show && (
        <PackageModal
          setShow={setShow}
          packageData={packageData}
          setPackageData={setPackageData}
          setToasted={setToasted}
          setToastedMsg={setToastedMsg}
        />
      )}
      <Toaster position="bottom-right" reverseOrder={false} />
    </Layout>
  );
}
export default Package;
