import { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";

import { Col, Container, Form, Row, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from "../Layout/mainLayout.js";
import SpinnerDev from "../components/Spinner/Spinner.js";
import UserDetails from "../components/Modal/details/userDetails.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../admin.scss";
import toast, { Toaster } from "react-hot-toast";

function PackageLessonsDetails() {
  const { packageId } = useParams();
  const [data, setData] = useState([]);
  const [allLessons, setAllLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New state for API loading status
  const [packageLessonsIds, setPackageLessonsIds] = useState([]);

  const handleAdd = () => {
    API.addLessonsToPackage(packageLessonsIds, packageId)
      .then((response) => {
        console.log("RESPONSE : ", response);
        toast.success("Successfully Added!");
      })
      .catch((err) => {
        console.warn(err);
        toast.error("Something went wrong!");
      });
  };

  useEffect(async () => {
    API.getPackageById(packageId).then((res) => {
      setData(res.data);
      let lessonsIds = res.data.lessions.map((item) => item.lessionId);
      setPackageLessonsIds(lessonsIds);
    });

    API.getAllLessons().then((res) => {
      res.data.forEach((lesson) => {
        if (packageLessonsIds.includes(lesson.lessionId)) {
          lesson.isPackageLesson = true;
        } else {
          lesson.isPackageLesson = false;
        }
      });
      setAllLessons(res.data);
    });

    setIsLoading(false); // Set loading status to false once data is received
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <SpinnerDev />
      ) : (
        <Container className="mb-4">
          <Row className="">
            <Col xs={12}>
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Name : {data.name}</th>
                    <th scope="col">
                      Active :{" "}
                      {data.isActive ? (
                        <i className="fas fa-check text-success"></i>
                      ) : (
                        <i className="fas fa-minus text-danger"></i>
                      )}
                    </th>
                  </tr>
                </thead>
              </table>
            </Col>
            <Col xs={12}>
              {allLessons.map((lesson, idx) => (
                <Button
                  key={idx}
                  variant={
                    packageLessonsIds.includes(lesson.lessionId)
                      ? "warning"
                      : "outline-warning"
                  }
                  className="m-2"
                  onClick={() => {
                    // change the lesson.isPackageLesson to the opposite
                    // lesson.isPackageLesson = !lesson.isPackageLesson;
                    // update packageLessonsIds either add or remove the lesson id
                    if (!packageLessonsIds.includes(lesson.lessionId)) {
                      setPackageLessonsIds([
                        ...packageLessonsIds,
                        lesson.lessionId,
                      ]);
                    } else {
                      setPackageLessonsIds(
                        packageLessonsIds.filter(
                          (id) => id !== lesson.lessionId
                        )
                      );
                    }
                  }}
                >
                  {lesson.name}
                </Button>
              ))}
            </Col>
            <Col className="text-center">
              <Button
                variant="success"
                className="font-weight-bold mt-5 p-3 rounded font-size-lg text-white"
                onClick={handleAdd}
              >
                Add <i className="fa fa-plus" aria-hidden="true"></i>
              </Button>
              <Toaster position="bottom-right" reverseOrder={false} />
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
}

export default PackageLessonsDetails;
