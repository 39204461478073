import { Spinner } from "react-bootstrap";

const SpinnerDev = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "55vh" }}>
        <div className="m-5" >
        <Spinner animation="grow" style={{
              height: "4rem",
              width: "4rem",
            }} variant="success" />
        <Spinner animation="grow" style={{
              height: "4rem",
              width: "4rem",
            }} variant="success" />
        <Spinner animation="grow" style={{
              height: "4rem",
              width: "4rem",
            }} variant="success" />
        <Spinner animation="grow" style={{
              height: "4rem",
              width: "4rem",
            }} variant="success" />
        </div>
    </div>
  );
};

export default SpinnerDev;
