import React from "react";
import { Provider } from "react-redux";
const { useEffect, useState } = React;
import { BrowserRouter as Router } from "react-router-dom";
import store from "./core/store/configureStore";
import AppRouter from "./core/router/router";
import { LocalizationProvider } from "./core/localization/localizationProvider";
import "./resource/styles/base.scss";
import './App.scss';
import AppLogo from "./resource/img/happy-math-logo.svg";
import API from './core/services/userService';
import toast, { Toaster } from "react-hot-toast";


import ResetPassword from "./pages/admin/components/Modal/resetPassword"


function App() {
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [uname, setuname] = useState('');
    const [pass, setpass] = useState('');
    const [isAuthenticated, setisAuthenticated] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [toasted, setToasted] = useState("");
    const [toastedMsg, setToastedMsg] = useState("");

    useEffect(() => {
      if (toasted == "success") {
        toast.success(toastedMsg);
        setToasted("")
      } else if (toasted == "error") {
        toast.error(toastedMsg);
        setToasted("")
      }
    }, [showReset]);

    useEffect(() => {
        console.log("isAuthenticated");
        console.log(isAuthenticated);
        var isLoogedIN = localStorage.getItem('isAuthenticated');
        if (isLoogedIN)
            setisAuthenticated(isLoogedIN);
        else
            setisAuthenticated(isAuthenticated);

    }, [isAuthenticated]);

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();


        console.log({ uname })
        console.log({ pass })


        // Find user login info

        API.Login({ uname, pass }).then((response) => {
            console.log("response from App.js");
            console.log(response);
            var userData = response;

            if (userData?.isAuthenticated) {
                console.log(userData);
                setisAuthenticated(true);
                //redirict to another page 
                console.log(userData);


            }
            else {
                // Username not found
                setErrorMessages({ name: "uname", message: errors.uname });

            }

        });



    };
    // Generate JSX code for error message
    const renderErrorMessage = (name) =>

        name === errorMessages.name && (
            <div className="alert alert-danger" role="alert">{errorMessages.message}</div>
        );


    const errors = {
        uname: "Invalid Login"

    };

    const bootstrapForm = (


        <form className="form card" onSubmit={handleSubmit}>
            <img className="loginLogo card-img-top LoginLogo" src={AppLogo} alt="Card image cap" />
            <div className="card-body">
                {renderErrorMessage("uname")}
                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="uname">User Name</label>
                    <input type="text" className="form-control" name="username" required value={uname}
                        onChange={(e) => setuname(e.target.value)} />


                </div>


                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="pass">Password</label>
                    <input type="password" name="password" className="form-control" required value={pass}
                        onChange={(e) => setpass(e.target.value)} />


                </div>

                <div className="row mb-4">


                    <div className="col">
                            {/* on click on this it should toggle showReset */}
                        <a href="#" onClick={() => setShowReset(true)}>Forgot password?</a>

                    </div>
                </div>

                <input type="submit" className="btn btn-primary btn-block mb-4" value="Sign in" />

            </div>
        </form>);


    return (
      //{ isAuthenticated } ?
      //<div className="container LoginForm" >
      //    <div className="row">
      //        <div className="mx-auto col-10 col-md-8 col-lg-6">
      //            {bootstrapForm}

      //        </div>
      //    </div>

      //    </div> :
      //    <AppRouter/>

      <Provider store={store}>
        <Router devMode={true}>
          <LocalizationProvider langId="en-us">
            {isAuthenticated ? (
              <AppRouter isAuthenticated={isAuthenticated} />
            ) : (
              <div className="container LoginForm">
                <div className="row">
                  <div className="mx-auto col-10 col-md-8 col-lg-6">
                    {bootstrapForm}
                    {showReset && (
                      <ResetPassword
                        setShow={setShowReset}
                        userData={{}}
                        setToasted={setToasted}
                        setToastedMsg={setToastedMsg}
                        admin={false}
                      />
                    )}
                    <Toaster position="bottom-right" reverseOrder={false} />
                  </div>
                </div>{" "}
              </div>
            )}
          </LocalizationProvider>
        </Router>
      </Provider>
    );
}


export default App;

