import { useEffect, useState } from "react";
import API from "../../../../core/services/adminServices.js";

import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function ResetPassword({
  setShow,
  userData,
  setUserData,
  setToasted,
  setToastedMsg,
  admin,
}) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formLabel, setFormLabel] = useState("Reset Password");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [uName, setUName] = useState("");
  const [uPhone, setUPhone] = useState("");

  useEffect(() => {
    if (password === confirmPassword && password.length > 0) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [password, confirmPassword]);
  useEffect(() => {
    if (admin) {
      setUName(userData.userName);
      setUPhone(userData.phoneNumber);
    }
  }, []);
  
  const handleSubmit = () => {
    API.ResetPassword(uName, password, uPhone)
      .then(function (response) {
        console.log(response);
        if (response?.data.isSucceed) {
          setToasted("success");
          setToastedMsg(response?.data?.message);
        } else {
          setToasted("error");
          setToastedMsg(response?.data?.message);
        }
        setShow(false);
        setUserData({})
      })
      .catch(function (error) {
        setToasted("error");
        console.log(error);
      });
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formLabel}</Modal.Title>
        </Modal.Header>
        <Form className="p-4">
          {!admin && (
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="User Name"
                    onChange={(e) => {
                      setUName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="phone Number"
                    onChange={(e) => {
                      setUPhone(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </Form.Group>

          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={btnDisabled}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default ResetPassword;
