import axios from 'axios';
import jwt_decode from "jwt-decode";


const baseApiUrl = process.env.REACT_APP_API_URL;

//const getCurrentUser = () =>{
//  const headers = new Headers();
//  const bearer = `Bearer ${localStorage.getItem('lmstoken')}`;
//    const url = `${baseApiUrl}/user/currentuser`;
//    return axios.get(url,{
//        headers: {
//          Authorization: bearer
//        }
//    });
//}
const Login = (data) => {
    debugger;
    const url = `${baseApiUrl}/user/Login`;
    return axios.post(url, data)
        .then(res => {
            if(res.data.isAuthenticated && res.data.userToken){
                localStorage.setItem('lmstoken', res.data.userToken)
                localStorage.setItem('refreshToken', res.data.refreshToken)
                localStorage.setItem('User', JSON.stringify(res.data))
                localStorage.setItem('isAuthenticated', res.data.isAuthenticated)
                var decodedJwt = jwt_decode(res.data.userToken);
                localStorage.getItem('tokenExpiration', decodedJwt.exp);
                localStorage.setItem('Role', decodedJwt['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'])
                return res.data;
            }
        })
        .catch(err => console.log(err));
}
export default {
    Login
}