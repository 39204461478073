import { useEffect, useState } from "react";
import API from "../../../../core/services/adminServices.js";

import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function UserModalBulk({
  setShowBulk,
  sectionId,
  setToasted,
    setToastedMsg,
    setalermsg,
    isLoading, 
    setIsLoading
}) {
  const [formLabel, setFormLabel] = useState("Add Users");
    const [file, setFile] = useState("");
  useEffect(() => {
    // API.getAllSchools().then((res) => {
    //   setSchools(res.data);
    // });
    // API.getAllRoles().then((res) => {
    //   setRoles(res.data);
    // });
  }, []);

  const handleSubmit = () => {
      setIsLoading(true);
      API.uploadFileUsersBulk(
        file,
        sectionId
      )
        .then(function (response) {
          console.log(response);
          if (response?.data.isSucceed) {
            setToasted("success");
              setToastedMsg("Users Added Successfully!");
              setalermsg(response?.data.message)
              
          } else {
            setToasted("error");
          }
          setShowBulk(false);
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
          setToasted("error");
        });
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShowBulk(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formLabel}</Modal.Title>
        </Modal.Header>
        <Form className="p-4">
            <Form.Group controlId="formBasicEmail">
                <Form.Label>Upload File</Form.Label>
                <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
            </Form.Group>
          <Button variant="success" onClick={handleSubmit} disabled={!file}>
            Submit
           </Button>
        </Form>
      </Modal>
    </>
  );
}

export default UserModalBulk;
