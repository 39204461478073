import { useEffect, useState } from "react";
import API from "../../../core/services/adminServices.js";
import  handleExportToExcel  from "../../../core/utils/exportAsExcel.js";

import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Layout from "./../Layout/mainLayout.js";
import SpinnerDev from "./../components/Spinner/Spinner.js";
import MessageComponent from "./../components/noData/nodata.js";

import Button from "react-bootstrap/Button";

import toast, { Toaster } from "react-hot-toast";
import SectionModal from "../components/Modal/sectionModal.js";
import { BackArrow } from "../components/Icons/generalIcons.js";

function SectionByGradeId() {
  const { schoolId, gradeId } = useParams();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [sectionData, setSectionData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [toasted, setToasted] = useState("");
  const [toastedMsg, setToastedMsg] = useState("");

  useEffect(() => {
    if (toasted == "success") {
      toast.success(toastedMsg);
      setToasted("");
    } else if (toasted == "error") {
      toast.error("Something went wrong!");
      setToasted("");
    }
    API.getSectionsByGradeId(gradeId).then((res) => {
      setData(res.data);
      setIsLoading(false); // Set loading status to false once data is received
    });
  }, [show]);

  return (
    <Layout>
      {isLoading ? (
        <SpinnerDev />
      ) : (
        <Container className="mb-4">
          <Row className="">
              <Link to={`/admin/schools/${schoolId}/grade`}>
                <BackArrow width={24} height={24} />
              </Link>
            <Col xs={12} className="d-flex justify-content-end">
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  setShow(true);
                  setSectionData({});
                }}
              >
                Add New Section
              </Button>
              <Button
                className="m-3"
                variant="success"
                onClick={() => {
                  handleExportToExcel('sections', data);
                }}
              >
                Export As Excel
                <span className="ml-2">
                  <i className="fas fa-file-excel"></i>
                </span>
              </Button>
            </Col>
            <Col xs={12}>
              {data.length === 0 ? (
                <MessageComponent
                  message={`There is no sections found for this grade`}
                />
              ) : (
                <table className="table table-striped border">
                  <thead>
                    <tr>
                      {/*<th scope="col">ID</th>*/}
                      <th scope="col">Name</th>
                      <th scope="col">Grade </th>
                      <th scope="col">Teacher Name</th>
                      <th scope="col">Active</th>
                      <th scope="col">Edit</th>
                      <th scope="col">Users</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, idx) => (
                      <tr key={idx}>
                        {/*<td>{`${item.sectionId}`}</td>*/}
                        <td>{`${item.name}`}</td>
                        <td>{`${item.gradeName}`}</td>
                        <td>{`${item.teacherName}`}</td>
                            <td>
                                {item.isActive ? (
                                    <i className="fas fa-check text-success"></i>
                                ) : (
                                    <i className="fas fa-minus text-danger"></i>
                                )} 
                            </td>
                        <td>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShow(true);
                            setSectionData(item);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </span>
                        </td>
                        <td>
                          <Link to={`/admin/schools/sections/${item.sectionId}/user`}>
                            <Button variant="outline-success">
                              View Users
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Col>
          </Row>
        </Container>
      )}
      {show && (
        <SectionModal
          schoolId={schoolId}
          gradeId={gradeId}
          setShow={setShow}
          sectionData={sectionData}
          setSectionData={setSectionData}
          setToasted={setToasted}
          setToastedMsg={setToastedMsg}
        />
      )}
      <Toaster position="bottom-right" reverseOrder={false} />{" "}
    </Layout>
  );
}

export default SectionByGradeId;
