import React, { useEffect, useState } from "react";
import API from "../../core/services/adminServices.js";
import SpinnerDev from "./components/Spinner/Spinner.js";


import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./admin.scss";
import Title from "./components/title";
import Tile from "./components/tile";
import Organization from "./components/organization";
import Users from "./components/users";
import Layout from "./Layout/mainLayout";
import { Col, Container, Row } from "react-bootstrap";
import {
  OrganizationIcon,
  PackageIcon,
  SchoolIcon,
  UsersIcon,
} from "./components/Icons/sidebarIcons";
import CanvasJSReact from "@canvasjs/react-charts";
import axios from "axios";
import {
  OrganizationOption,
  PackageOption,
  SchoolsOption,
  UserOption,
  options,
} from "./chartsOptions/options";
import Chart from "react-apexcharts";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Dashboard() {
  const [users, setUsers] = useState({});
  const [schools, setSchools] = useState({});
  const [organization, setOrganizations] = useState({});
  const [packages, setPackages] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    API.getDashboardItems().then((res) => {
      setUsers({
        active: res.data.numberOFActiveUsers,
        inactive: res.data.numberOfInActiveUsers,
        total: res.data.numberOFActiveUsers + res.data.numberOfInActiveUsers,
      });
      setSchools({
        active: res.data.numberOFActiveSchools,
        inactive: res.data.numberOfInActiveSchools,
        total:
          res.data.numberOFActiveSchools + res.data.numberOfInActiveSchools,
      });
      setOrganizations({
        active: res.data.numberOFActiveORG,
        inactive: res.data.numberOFINActiveORG,
        total: res.data.numberOFActiveORG + res.data.numberOFINActiveORG,
      });
      setPackages({
        active: res.data.numberOfActivePackages,
        inactive: res.data.numberOfINActivePackages,
        total:
          res.data.numberOfActivePackages + res.data.numberOfINActivePackages,
      });
      setIsLoading(false);
    });
  }, []);

  return (
    <Layout>
      {isLoading ? <SpinnerDev /> :
      <Container className="mb-4">
        <Row>
          <Col xxl={6} xl={6} lg={6} xs={12} className="mb-3">
            <div className="border border-gray-100 rounded">
              <div
                className={`mb-3 border-bottom border-gray-100 fw-bold d-flex justify-content-between p-3`}
              >
                <div>
                  <Link to="/admin/users">
                    <h2>Users</h2>
                  </Link>
                </div>
                <div>
                  <UsersIcon width={24} height={24} />
                </div>
              </div>
              <div className="mb-4 chartDiv">
                {/* <CanvasJSChart options={UserOption(users)} /> */}
                <Chart
                  options={UserOption(users).options}
                  series={UserOption(users).series}
                  type="pie"
                  width="500"
                />
              </div>
            </div>
          </Col>
          <Col xxl={6} xl={6} lg={6} xs={12} className="mb-3">
            <div className="border border-gray-100 rounded">
              <div
                className={`mb-4 border-bottom border-gray-100 fw-bold d-flex justify-content-between p-3`}
              >
                <div className="">
                  <Link to="/admin/schools">
                    <h2>Schools</h2>
                  </Link>
                </div>
                <div>
                  <SchoolIcon width={24} height={24} />
                </div>
              </div>
              <div className="mb-4 chartDiv">
                {/* <CanvasJSChart
                  options={SchoolsOption(schools)}
                /> */}
                <Chart
                  options={SchoolsOption(schools).options}
                  series={SchoolsOption(schools).series}
                  type="donut"
                  width="500"
                />
              </div>
            </div>
          </Col>
          <Col xxl={6} xl={6} lg={6} xs={12} className="mb-3">
            <div className="border border-gray-100 rounded">
              <div
                className={`mb-4 border-bottom border-gray-100 fw-bold d-flex justify-content-between p-3`}
              >
                <div className="">
                  <Link to="/admin/organizations">
                    <h2>Organizations</h2>
                  </Link>
                </div>
                <div>
                  <OrganizationIcon width={24} height={24} />
                </div>
              </div>
              <div className="mb-4 chartDiv">
                {/* <CanvasJSChart
                  options={OrganizationOption(organization)}
                /> */}
                <Chart
                  options={OrganizationOption(organization).options}
                  series={OrganizationOption(organization).series}
                  type="donut"
                  width="500"
                />
              </div>
            </div>
          </Col>
          <Col xxl={6} xl={6} lg={6} xs={12} className="mb-3">
            <div className="border border-gray-100 rounded">
              <div
                className={`mb-2 border-bottom border-gray-100 fw-bold d-flex justify-content-between p-3`}
              >
                <div className="">
                  <Link to="/admin/packages">
                    <h2>Packages</h2>
                  </Link>
                </div>
                <div>
                  <PackageIcon width={24} height={24} />
                </div>
              </div>
              <div className="mb-4 chartDiv">
                {/* <CanvasJSChart
                  options={PackageOption(packages)}
                /> */}
                <Chart
                  options={PackageOption(packages).options}
                  series={PackageOption(packages).series}
                  type="pie"
                  width="500"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
}
    </Layout>
  );
}

export default Dashboard;