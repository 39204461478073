import { useEffect, useState } from "react";
import API from "../../../../core/services/adminServices.js";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function UserModal({
  setShow,
  userData,
  setUserData,
  setToasted,
  setToastedMsg,
}) {
  const [firstName, setFirstName] = useState(userData.firstName || "");
  const [lastName, setLastName] = useState(userData.lastName || "");
  const [email, setEmail] = useState(userData.email || "");
  const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber || "");
  const [password, setPassword] = useState("");
  const [schoolId, setSchoolId] = useState(userData.schoolId || "");
  const [isActive, setIsActive] = useState(userData.isActive || false);
  const [roleName, setRoleName] = useState(userData.roleName || "");
  const [schools, setSchools] = useState([]);
  const [formLabel, setFormLabel] = useState("Add User");
  const [roles, setRoles] = useState([{}]);
  const [schoolName, setSchoolName] = useState("");

  const [grades, setGrades] = useState([]);
  const [gradeId, setGradeId] = useState(0);

  const [sections, setSections] = useState([]);
  const [sectionId, setSectionId] = useState(0);

  useEffect(() => {
    if (Object.keys(userData).length !== 0) setFormLabel("Update User");
    API.getAllSchools().then((res) => {
      setSchools(res.data);
      if (!userData.schoolId) {
        setSchoolId(res.data[0]?.schoolId);
      }
      if (userData.schoolId) {
        const school = res.data.find(
          (school) => school.schoolId === userData.schoolId
        );
        setSchoolName(school.name);
        const filteredSchools = res.data.filter(
          (school) => school.schoolId !== userData.schoolId
        );
        setSchools(filteredSchools);
      }
    });
    API.getAllRoles().then((res) => {
      setRoles(res.data);
      if (!userData.roleName) {
        setRoleName(res.data[0]?.roleName);
      }
      if (userData.roleName) {
        // filter roles and make the selected role the first one
        const role = res.data.find(
          (role) => role?.roleName === userData?.roleName
        );
        setRoleName(role?.roleName || '');

        const filteredRoles = res.data.filter(
          (role) => role?.roleName !== userData?.roleName
        );
        setRoles([role, ...filteredRoles]);
      }
    });
  }, []);

  useEffect(() => {
    API.getGradesBySchoolId(schoolId).then((res) => {
      setGrades(res?.data);
      setGradeId(res?.data[0]?.gradeId);
    });
  }, [schoolId]);

  useEffect(() => {
    API.getSectionsByGradeId(gradeId).then((res) => {
      setSections(res?.data);
      setSectionId(res?.data[0]?.sectionId);
    });
  }, [gradeId]);

  const isFormValid =
    firstName !== "" &&
    lastName !== "" &&
    email !== "" &&
    phoneNumber !== "" &&
    schoolId !== "" &&
    roleName !== "" &&
    gradeId !== "" &&
    sectionId !== "" &&
    (Object.keys(userData).length != 0 || password !== "");

  const handleSubmit = () => {
    if (Object.keys(userData).length === 0) {
      API.createUser(
        Number(schoolId),
        roleName,
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        gradeId,
        sectionId
      )
        .then(function (response) {
          console.log(response);
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("User Added Successfully!");
          } else {
            setToasted("error");
            setToastedMsg("Failed to add user");
          }
          setShow(false);
          setUserData({});
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    } else {
      API.updateUser(
        Number(schoolId),
        roleName,
        firstName,
        lastName,
        email,
        phoneNumber,
        isActive,
        userData.userName,
        gradeId,
        sectionId
      )
        .then(function (response) {
          console.log(response);
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("User Updated Successfully!");
          } else {
            setToasted("error");
          }
          setShow(false);
          setUserData({});
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShow(false);
          setUserData({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formLabel}</Modal.Title>
        </Modal.Header>
        <Form className="p-4">
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  defaultValue={userData.firstName ? userData.firstName : ""}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  defaultValue={userData.lastName ? userData.lastName : ""}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  defaultValue={userData.email ? userData.email : ""}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="number">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Phone number"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  defaultValue={
                    userData.phoneNumber ? userData.phoneNumber : ""
                  }
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          {Object.keys(userData).length === 0 && (
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
              />
            </Form.Group>
          )}

          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Select School</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setSchoolId(e.target.value);
              }}
              defaultValue={
                userData.schoolId ? userData.schoolId : schools[0]?.schoolId
              }
              required
            >schoolName
              <option>{schoolName || 'Choose School'}</option>

              {schools &&
                schools.map((e, key) => {
                  return (
                    <option
                      key={key}
                      value={e.schoolId}
                      defaultValue={userData.schoolId === e.schoolId}
                    >
                      {e.name}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>

          {grades && (
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Select Grade</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setGradeId(e.target.value);
                }}
                defaultValue={
                  userData.gradeId ? userData.gradeId : grades[0]?.gradeId
                }
                required
              >
                {grades &&
                  grades.map((e, key) => {
                    return (
                      <option
                        key={key}
                        value={e.gradeId}
                        defaultValue={e.gradeId}
                      >
                        {e.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Form.Group>
          )}

          {sections && (
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Select Section</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setSectionId(e.target.value);
                }}
                defaultValue={
                  userData.sectionId
                    ? userData.sectionId
                    : sections[0]?.sectionId
                }
                required
              >
                {sections &&
                  sections.map((e, key) => {
                    return (
                      <option
                        key={key}
                        value={e.sectionId}
                        defaultValue={e.sectionId}
                      >
                        {e.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Form.Group>
          )}

          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Select Role</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setRoleName(e.target.value);
              }}
              defaultValue={
                userData?.roleName ? userData?.roleName : roles[0].roleName
              }
              required
            >
              {roles &&
                roles.map((e, key) => {
                  return (
                    <option
                      key={key}
                      value={e?.roleName}
                      defaultValue={userData?.roleName === e?.roleName}
                    >
                      {e?.roleName}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>

          {Object.keys(userData).length !== 0 && (
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Active"
                checked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                }}
              />
            </Form.Group>
          )}

          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!isFormValid}
          >
            {Object.keys(userData).length === 0 ? "Submit" : "Update"}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default UserModal;
