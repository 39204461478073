import axios from "axios";

const adminBaseUrl = process.env.REACT_APP_ADMIN_API_URL;


const getAllLessons = () => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/GetAllLessons`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const getAllOrganizations = () => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/organizations`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const addOrganization = (name, description) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/AddOrganization`;
    return axios.post(url, {
        name,
        description,
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};

const updateOrganization = (organizationId, name , description, isActive ) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/Organization/updateorganization`;
    return axios.post(url, {
        organizationId,
        name,
        description,
        isActive,
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};


const getAllPackages = () => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/Package/getallpackages?includeDisabled=true`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const addPackage = (packageName, isActive) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/Package/CreatePackage`;
    return axios.post(url, {
        packageName,
        isActive,
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};

const updatePackage = (packageId, packageName, isActive) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/Package/updatePackage`;
    return axios.post(url, {
        packageId,
        packageName,
        isActive,
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};

const getPackageById = (packageId) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/Package/${packageId}`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const addLessonsToPackage = (packageLessonsIds, packageId) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/Package/AddlessonsToPackage`;
    return axios.post(url, packageLessonsIds, {
        params: {
            packageId,
        },
        headers: {
            Authorization: bearer,
        },
    });
};

const getAllRoles = () => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/getallroles`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const addRole = (name) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/admin/AddRole`;
    return axios.post(url, {}, {
        params: {
            name,
        },
        headers: {
            Authorization: bearer,
        },
    });
};


const getUsersByRoleName = (roleName) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/getusersbyrole?roleName=${roleName}`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const getAllSchools = () => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/org/getallschools`;
    return axios.get(url, {
        params: {
            includeInActive: true
        },
        headers: {
            Authorization: bearer,
        },
    });
};

const getAllSchoolsByOrganizationId = (organizationId) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/org/${organizationId}`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const getUsersBySchoolId = (schoolId) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/getusersbyschoolid?schoolid=${schoolId}`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};


const addSchool = (name, address, phoneNumber, organizationId, packageId, stateId, countryId, academicYear, comments, rate) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/AddSchool`;
    return axios.post(url, {
        name,
        address,
        phoneNumber,
        organizationId,
        packageId,
        stateId,
        countryId,
        academicYear,
        comments,
        rate
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};

const updateSchool = (schoolId, name , address, phoneNumber, organizationId, packageId,isActive, stateId, countryId, academicYear, comments, rate) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/updateschool`;
    return axios.post(url, {
        schoolId,
        name,
        address,
        phoneNumber,
        organizationId,
        packageId,
        isActive,
        stateId,
        countryId,
        academicYear,
        comments,
        rate
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};

const getAllUsers = () => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/users`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const createUser = (schoolId, roleName, firstName, lastName, email, phoneNumber, password, gradeId, sectionId) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/user/CreateUser`;
    return axios.post(url, {schoolId, roleName, firstName, lastName, email, phoneNumber, password, gradeId, sectionId
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};

const updateUser = (schoolId, roleName, firstName, lastName, email, phoneNumber, isActive, userName, gradeId, sectionId) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/updateuser`;
    return axios.post(url, {schoolId, roleName, firstName, lastName, email, phoneNumber, isActive, userName, gradeId, sectionId
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};


const getDashboardItems = () => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/getdashboarditems`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const ResetPassword = (username, password, phoneNumber) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/resetpassword?newpassword=${password}&username=${username}&phoneNumber=${phoneNumber}`;
    return axios.post(url, {}, {
        headers: {
            Authorization: bearer,
        },
    });
};

const getGradesBySchoolId = (id) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/GetGradesBySchoolID?schoolID=${id}`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const getSectionsByGradeId = (id) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/GetSectionsByGradeID?gradeId=${id}`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const addGrade = (name, schoolId, isActive) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/AddNewGrade`;
    return axios.post(url, {
        name,
        schoolId,
        isActive
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};

const updateGrade = (id, name,isActive) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/UpdateGrade`;
    return axios.post(url, {
        gradeId: id,
        name,
        isActive

    }, {
        headers: {
            Authorization: bearer,
        },
    });
};


const getTeacher = (id) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/GetTeachersBySchoolID?SchoolID=${id}`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const addSection = (name, gradeId, teacherId, isActive) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/AddNewSection`;
    return axios.post(url, {
        name,
        gradeId,
        teacherId,
        isActive
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};

const updateSection = (id, name, teacherId,isActive) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/UpdateSection`;
    return axios.post(url, {
        sectionId: id,
        name,
        teacherId,
        isActive
    }, {
        headers: {
            Authorization: bearer,
        },
    });
};


const getUsersBySectionId = (id) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/GetStudentBySection?sectionID=${id}`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};


const uploadFileUsersBulk = (file, sectionId) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    let data = new FormData();
    data.append('file', file);
    data.append('sectionId', sectionId);
    const url = `${adminBaseUrl}/api/user/UploadAccountUserBulk`;
    // the file is in the body and its file string($binary)
    // the sectionId is in the body also
    return axios.post(url, data, {
        headers: {
            Authorization: bearer,
        },
    });
};


const getAllCountries = () => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/GetAllCountries`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};

const getStatesByCountryId = (id) => {
    const bearer = `Bearer ${localStorage.getItem("lmstoken")}`;
    const url = `${adminBaseUrl}/api/School/GetStateByCountryID?CID=${id}`;
    return axios.get(url, {
        headers: {
            Authorization: bearer,
        },
    });
};



export default {
    getAllLessons,
    getAllOrganizations,
    updateOrganization,
    addOrganization,
    getAllPackages,
    addPackage,
    updatePackage,
    getPackageById,
    addLessonsToPackage,
    getAllRoles,
    addRole,
    getUsersByRoleName,
    getAllSchools,
    getAllSchoolsByOrganizationId,
    addSchool,
    updateSchool,
    getUsersBySchoolId,
    getAllUsers,
    createUser,
    updateUser,
    getDashboardItems,
    ResetPassword,
    getGradesBySchoolId,
    getSectionsByGradeId,
    addGrade,
    updateGrade,
    getTeacher,
    addSection,
    updateSection,
    getUsersBySectionId,
    uploadFileUsersBulk,
    getAllCountries,
    getStatesByCountryId,
};