import { useEffect, useState } from "react";
import API from "../../../../core/services/adminServices.js";

import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function SectionModal({
  schoolId,
  gradeId,
  setShow,
  sectionData,
  setSectionData,
  setToasted,
  setToastedMsg,
}) {
  const [name, setName] = useState(sectionData.name || "");
    const [formLabel, setFormLabel] = useState("Add Section");
    const [isActive, setIsActive] = useState(sectionData.isActive || false);

  const [teachers, setTeachers] = useState([]);
  const [teacherId, setTeacherId] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (Object.keys(sectionData).length !== 0) setFormLabel("Update Section");
    API.getTeacher(schoolId)
      .then((res) => {
        setTeachers(res.data);
        if (sectionData.teacherName === "NA") {
          setTeacherId(res.data[0]?.id || "");
        } else {
          const section = res.data.find(
              (teacher) => teacher.id === sectionData.teacherId
          );
          if (section) {
            setTeacherId(section.id);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [schoolId, sectionData]);

  useEffect(() => {
    setIsFormValid(name.trim().length > 0);
  }, [name]);

  const handleSubmit = () => {
      if (Object.keys(sectionData).length === 0) {
          API.addSection(name, gradeId, teacherId, isActive)
        .then(function (response) {
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("Section Added Successfully!");
          } else {
            setToasted("error");
          }
          setShow(false);
          setSectionData({});
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    } else {
        API.updateSection(sectionData.sectionId, name, teacherId, isActive)
        .then(function (response) {
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("Section Updated Successfully!");
          } else {
            setToasted("error");
          }
          setShow(false);
          setSectionData({});
        })
        .catch(function (error) {
          console.log(error);
          setToasted("error");
        });
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShow(false);
          setSectionData({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formLabel}</Modal.Title>
        </Modal.Header>
        <Form className="p-4">
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Section Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              required // Set the input as required
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Select Teacher</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setTeacherId(e.target.value);
              }}
              value={teacherId}
            >
              {teachers.map((teacher) => (
                <option key={teacher.id} value={teacher.id}>
                  {teacher.firstName + " " + teacher.lastName}
                </option>
              ))}
            </Form.Control>
                  </Form.Group>
                  {Object.keys(sectionData).length !== 0 && (
                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check
                              type="checkbox"
                              label="Active"
                              checked={isActive}
                              onChange={(e) => {
                                  setIsActive(e.target.checked);
                              }}
                          />
                      </Form.Group>
                  )}
          <Button variant="success" onClick={handleSubmit} disabled={!isFormValid}>
            {Object.keys(sectionData).length === 0 ? "Submit" : "Update"}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default SectionModal;
