import React, { useState } from "react";
import Loadable from "../loader/loadable";
import { Route, Switch, Redirect } from "react-router-dom";
import axiosConfig from "../helpers/axios.config";
import ErrorHandler from "../../shared/errorHandler";
import { useIsAuthenticated, useMsal ,useAccount} from "@azure/msal-react";
import Menu from "../../shared/menu";
import User from "./../../pages/admin/users/user";
import Role from "./../../pages/admin/roles/role";
import UsersByRoleDetails from "./../../pages/admin/roles/usersByRoleDetails";
import UsersBySchoolDetails from "./../../pages/admin/schools/userBySchoolIdDetails";
import UsersBySectionIdDetails from "../../pages/admin/sections/userBySectionIdDetails";
import OrgSchoolsDetails from "./../../pages/admin/organizations/orgSchoolsDetails";
import PackageLessonsDetails from "./../../pages/admin/packagees/packageLessonsDetails";
// import UserId from "./../../pages/admin/users/userId";
import Organization from "./../../pages/admin/organizations/organization";
import Lessons from "./../../pages/admin/lessons/lesson";
import School from "./../../pages/admin/schools/school";
import Package from "./../../pages/admin/packagees/package";
import Dashboard from "./../../pages/admin/index";
import GradeBySchoolId from "../../pages/admin/grades/grade";
import SectionByGradeId from "../../pages/admin/sections/section";

axiosConfig.configure();

const loginRequest = {
  scopes: ["api://00157580-7668-48db-9766-31c77e05f1a3/access_as_user"]
};

const Content = Loadable({
  loader: () => import("../../pages/content"),
});

const Assignment = Loadable({
  loader: () => import("../../pages/assignment"),
});

const CreateAssignment = Loadable({
  loader: () => import("../../pages/createAssignment"),
});

const Admin = Loadable({
  loader: () => import("../../pages/admin"),
});

export default function Router() {
  const { instance,accounts } = useMsal();
/*  const account = useAccount(accounts[0] || {});*/
  /*const isAuthenticated = useIsAuthenticated();*/
  const [minimize, setminimize] = useState(false);

  //  React.useEffect(() => {
       
  //  if (!isAuthenticated) {
  //   handleLogin(instance);
  //  } else {
  //    instance.acquireTokenSilent({
  //      scopes: loginRequest.scopes,
  //      account: account
  //      }).then((response) => {
  //         localStorage.setItem('lmstoken',response.accessToken);           
  //      }).catch((error) => {
  //          // in case if silent token acquisition fails, fallback to an interactive method
  //         console.error('Error with fetching token', error);
  //      });
  //  }
  //}, [isAuthenticated]);



    const [isAuthenticated, setisAuthenticated] = useState(localStorage.getItem('isAuthenticated'));
    
    React.useEffect(() => {
        debugger;
        setisAuthenticated(localStorage.getItem('isAuthenticated'));

    }, [isAuthenticated]);
  function handleLogin(instance) {
    instance
      .loginRedirect(loginRequest)
      .catch((e) => {
        console.error(e);
      });
  }

  return (
    <ErrorHandler>
      {isAuthenticated ? (
        <>
          {!minimize && <Menu />}
          <div className="main">
            <Route
              path="/"
              render={(props) => (
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(props) => <Content {...props} />}
                  />
                  <Route
                    exact
                    path="/content/:param1?/:param2?/:param3?/:param4?/:param5?"
                    render={(props) => (
                      <Content
                        {...props}
                        minimize={(st) => {
                          // setminimize(st);
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/assignment/:id?"
                    render={(props) => <Assignment {...props} />}
                  />
                  <Route
                    exact
                    path="/create-assignment"
                    render={(props) => <CreateAssignment {...props} />}
                  />
                  <Route
                    exact
                    path="/admin"
                    render={(props) => <Dashboard {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/users"
                    render={(props) => <User {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/roles"
                    render={(props) => <Role {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/roles/:roleName/user"
                    render={(props) => <UsersByRoleDetails {...props} />}
                  />
                  {/* <Route
                    exact
                    path="/admin/users/:id?"
                    render={(props) => <UserId {...props} />}
                  /> */}
                  <Route
                    exact
                    path="/admin/schools"
                    render={(props) => <School {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/schools/:schoolId/user" 
                    render={(props) => <UsersBySchoolDetails {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/schools/sections/:sectionId/user" 
                    render={(props) => <UsersBySectionIdDetails {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/schools/:schoolId/grade" 
                    render={(props) => <GradeBySchoolId {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/schools/:schoolId/grade/:gradeId/section" 
                    render={(props) => <SectionByGradeId {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/organizations"
                    render={(props) => <Organization {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/organizations/:organizationId/school"
                    render={(props) => <OrgSchoolsDetails {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/lessons"
                    render={(props) => <Lessons {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/packages"
                    render={(props) => <Package {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/packages/:packageId/add-lesson"
                    render={(props) => <PackageLessonsDetails {...props} />}
                  />
                </Switch>
              )}
            ></Route>
          </div>
        </>
      ) : (
        <span>You're not signed in</span>
      )}
    </ErrorHandler>
  );
}
