import { useEffect, useState } from "react";
import API from "../../../../core/services/adminServices.js";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function ModalComp({
  setShow,
  schoolData,
  setSchoolData,
  setToasted,
  setToastedMsg,
}) {
  const [name, setName] = useState(schoolData.name || "");
  const [address, setAddress] = useState(schoolData.address || "");
  const [phoneNumber, setPhoneNumber] = useState(schoolData.phoneNumber || "");
  const [organizationId, setOrganizationId] = useState(
    schoolData.organizationId || ""
  );
  const [organizations, setOrganizations] = useState([]);
  const [packageId, setPackageId] = useState(schoolData.packageId || "");
  const [isActive, setIsActive] = useState(schoolData.isActive || false);
  const [packages, setPackages] = useState([]);
  const [countryId, setCountryId] = useState(schoolData.countryId || "");
  const [countryName, setCountryName] = useState(undefined);
  const [stateId, setStateId] = useState(schoolData.stateId || "");
  const [comments,setComments] = useState(schoolData.comments || "")
  const [rate,setRate] = useState(schoolData.rate || "")

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const [organizationName, setOrganizationName] = useState(undefined);
  const [packageName, setPackageName] = useState(undefined);

  const [academicYear, setAcademicYear] = useState(
    schoolData?.academicYear?.split("T")[0] || formattedDate
  );

  const [countries, setCountries] = useState([]);
  const [statesOfCountry, setStatesOfCountry] = useState([]);

  const [formLabel, setFormLabel] = useState("Add School");

  useEffect(() => {
    if (Object.keys(schoolData).length !== 0) setFormLabel("Update School");
    API.getAllOrganizations().then((res) => {
      setOrganizations(res.data);
      if (schoolData.organizationId === "") {
      setOrganizationId(res.data[0].organizationId);
      }
      if (schoolData.organizationId) {
        const organization = res.data.find(
          (organization) => organization.organizationId === schoolData.organizationId
        );
        setOrganizationName(organization?.name);
        const filteredOrganizations = res.data.filter(
          (organization) => organization.organizationId !== schoolData.organizationId
        );
        setOrganizations(filteredOrganizations);
      }
    });
    API.getAllPackages().then((res) => {
      setPackages(res.data);
      if(schoolData.packageId === ""){
        setPackageId(res.data[0].id);
      }
      if (schoolData.packageId) {
        const packageObj = res.data.find(
          (packageObj) => packageObj.packageId === schoolData.packageId
          );
        setPackageName(packageObj?.name);
        const filteredPackages = res.data.filter(
          (packageObj) => packageObj.packageId !== schoolData.packageId
        );
        setPackages(filteredPackages);
      }
    });
    API.getAllCountries().then((res) => {
      setCountries(res.data);
      if (schoolData.countryId === "") {
        setCountryId(res.data[0].id);
      }
      if (schoolData.countryId) {
        const country = res.data.find(
          (country) => country.id === schoolData.countryId
        );
        setCountryName(country?.name);
      }
    });
    if (countryId) {
      API.getStatesByCountryId(countryId).then((res) => {
        setStatesOfCountry(res.data);
      });
    }
  }, []);

  useEffect(() => {
    if (countryId) {
      API.getStatesByCountryId(countryId).then((res) => {
        setStatesOfCountry(res?.data);
        if (stateId === "") {
          setStateId(res?.data[0]?.id);
        }
      });
    }
  }, [countryId]);

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      name !== "" &&
      address !== "" &&
      phoneNumber !== "" &&
      organizationId !== "" &&
      packageId !== "" &&
      countryId !== "" &&
      stateId !== "" &&
      academicYear !== ""
    );
  }, [
    name,
    address,
    phoneNumber,
    organizationId,
    packageId,
    countryId,
    stateId,
    academicYear,
    comments,
    rate,
  ]);

  const handleSubmit = () => {
    if (Object.keys(schoolData).length === 0) {
      API.addSchool(name, address, phoneNumber, organizationId, packageId, stateId, countryId, academicYear, comments, rate)
        .then(function (response) {
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("School Added Successfully");
          } else {
            setToasted("error");
          }
          setShow(false);
          setSchoolData({});
        })
        .catch(function (error) {
          setToasted("error");
        });
    } else {
      API.updateSchool(
        Number(schoolData.schoolId),
        name,
        address,
        phoneNumber,
        organizationId,
        packageId,
        isActive,
        stateId,
        countryId,
        academicYear,
        comments,
        rate
      )
        .then(function (response) {
          if (response?.data.isSucceed) {
            setToasted("success");
            setToastedMsg("School Updated Successfully");
          } else {
            setToasted("error");
          }
          setShow(false);
          setSchoolData({});
        })
        .catch(function (error) {
          setToasted("error");
        });
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShow(false);
          setSchoolData({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formLabel}</Modal.Title>
        </Modal.Header>
        <Form className="p-4">
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="School Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  defaultValue={schoolData.name ? schoolData.name : ""}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="School Address"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  defaultValue={schoolData.address ? schoolData.address : ""}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="School Phone Number"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  defaultValue={
                    schoolData.phoneNumber ? schoolData.phoneNumber : ""
                  }
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Registered Year</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Registered Year"
                  onChange={(e) => {
                    setAcademicYear(e.target.value);
                  }}
                  defaultValue={
                    schoolData.academicYear
                      ? schoolData?.academicYear?.split("T")[0]
                      : academicYear
                  }
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Organization</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setOrganizationId(e.target.value);
                  }}
                  defaultValue={
                    schoolData.organizationId
                      ? schoolData.organizationId
                      : organizations[0]?.organizationId
                  }
                  required
                >
                  <option>{organizationName || 'Choose Org'}</option>
                  {organizations &&
                    organizations.map((e, key) => {
                      return (
                        <option
                          key={key}
                          value={e.organizationId}
                          defaultValue={
                            schoolData.organizationId === e.organizationId
                          }
                        >
                          {e.name}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Package</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setPackageId(e.target.value);
                  }}
                  defaultValue={
                    schoolData.packageId
                      ? schoolData.packageId
                      : packages[0]?.packageId
                  }
                  required
                >
              <option>{packageName || 'Choose Package'}</option>
                  {packages &&
                    packages.map((e, key) => {
                      return (
                        <option
                          key={key}
                          value={e.packageId}
                          defaultValue={schoolData.packageId === e.packageId}
                        >
                          {e.name}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Countries</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setCountryId(e.target.value);
              }}
              defaultValue={
                schoolData.countryId ? schoolData.countryId : packages[0]?.id
              }
              required
            >
              <option>{countryName || 'Choose Country'}</option>
              {packages &&
                countries.map((e, key) => {
                  return (
                    <option
                      key={key}
                      value={e.id}
                      defaultValue={schoolData.countryId === e.id}
                    >
                      {e.name}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>

          {statesOfCountry.length > 0 && (
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>State</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setStateId(e.target.value);
                }}
                defaultValue={
                  schoolData.stateId
                    ? schoolData.stateId
                    : packages[0]?.id
                }
                required
              >
                {packages &&
                  statesOfCountry.map((e, key) => {
                    return (
                      <option
                        key={key}
                        value={e.id}
                        defaultValue={schoolData.stateId === e.id}
                      >
                        {e.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Form.Group>
          )}
            <Form.Group className="mb-3" controlId="name">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Comments"
                  onChange={(e) => {
                    setComments(e.target.value);
                  }}
                  defaultValue={
                    schoolData.comments
                      ? schoolData?.comments
                      : comments
                  }
                  required
                />
              </Form.Group>
            <Form.Group className="mb-3" controlId="name">
                <Form.Label>Rate</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Rate"
                  onChange={(e) => {
                    setRate(e.target.value);
                  }}
                  defaultValue={
                    schoolData.rate
                      ? schoolData?.rate
                      : rate
                  }
                  required
                />
              </Form.Group>

          {Object.keys(schoolData).length !== 0 && (
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Active"
                checked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                }}
              />
            </Form.Group>
          )}

          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!isFormValid}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default ModalComp;
